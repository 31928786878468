import { useRouter } from 'next/navigation';
import { Button } from 'ui';
import { ROUTES } from '@/constants/routes';
import Layout from '@/layouts/default';
import type { PageWithAuthType, PageWithLayoutType } from '@/types/pages';

const NotFound: PageWithLayoutType & PageWithAuthType = () => {
  const router = useRouter();

  return (
    <div className="min-h-content flex flex-col grow justify-around text-center">
      <div className="flex flex-col items-center gap-1">
        <p className="text-[33px] font-semibold text-neutral-700">
          Page not found
        </p>
        <span className="heading-400 text-neutral-700">
          {`Sorry, we are not able to find the page that you're looking for.`}
        </span>
        <Button
          layout="primary"
          className="w-44 mt-3 px-0"
          onClick={() => router.push(ROUTES.ROOT)}
        >
          <span className="heading-300">Go to fundings</span>
        </Button>
      </div>
    </div>
  );
};

NotFound.layout = Layout;
NotFound.requireAuth = true;

export default NotFound;
